// Generated by Framer (508aa67)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Image, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/YclOoM8LK";

const cycleOrder = ["tCQv29_q8"];

const serializationHash = "framer-i9eJ0"

const variantClassNames = {tCQv29_q8: "framer-v-9q7kfs"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, image, title, width, ...props}) => { return {...props, m3b7mFISp: title ?? props.m3b7mFISp ?? "Background Remover", v7siRVibc: image ?? props.v7siRVibc ?? {src: "https://framerusercontent.com/images/YPywguZEG6pPNzYMYQb4k2Mhm7E.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/YPywguZEG6pPNzYMYQb4k2Mhm7E.png?scale-down-to=1024 809w,https://framerusercontent.com/images/YPywguZEG6pPNzYMYQb4k2Mhm7E.png 1088w"}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string};title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, v7siRVibc, m3b7mFISp, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "tCQv29_q8", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} background={{alt: "", fit: "fill", sizes: componentViewport?.width || "100vw", ...toResponsiveImage(v7siRVibc)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-9q7kfs", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"tCQv29_q8"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><motion.div className={"framer-sbo79u"} data-framer-name={"Frame 2178"} layoutDependency={layoutDependency} layoutId={"I644:272266;1001:10547"}><motion.div className={"framer-qk93zh"} data-framer-name={"Product/Small (20px)"} layoutDependency={layoutDependency} layoutId={"I644:272266;1221:9490"}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1crqnco"} data-styles-preset={"YclOoM8LK"}>Background Remover</motion.p></React.Fragment>} className={"framer-fx7bbi"} data-framer-name={"Lorem ipsum dolor sit amet"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"I644:272266;1221:9490;293:2351"} text={m3b7mFISp} verticalAlignment={"top"} withExternalLayout/></motion.div></motion.div></Image></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-i9eJ0.framer-5n0ogt, .framer-i9eJ0 .framer-5n0ogt { display: block; }", ".framer-i9eJ0.framer-9q7kfs { height: 344px; overflow: hidden; position: relative; width: 272px; }", ".framer-i9eJ0 .framer-sbo79u { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 12px; height: min-content; justify-content: flex-start; left: 20px; overflow: visible; padding: 0px; position: absolute; top: 20px; width: 232px; }", ".framer-i9eJ0 .framer-qk93zh { align-content: flex-start; align-items: flex-start; display: flex; flex: 1 0 0px; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px; position: relative; width: 1px; }", ".framer-i9eJ0 .framer-fx7bbi { flex: none; height: auto; max-width: 150px; position: relative; white-space: pre-wrap; width: 100%; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-i9eJ0 .framer-sbo79u, .framer-i9eJ0 .framer-qk93zh { gap: 0px; } .framer-i9eJ0 .framer-sbo79u > * { margin: 0px; margin-left: calc(12px / 2); margin-right: calc(12px / 2); } .framer-i9eJ0 .framer-sbo79u > :first-child, .framer-i9eJ0 .framer-qk93zh > :first-child { margin-left: 0px; } .framer-i9eJ0 .framer-sbo79u > :last-child, .framer-i9eJ0 .framer-qk93zh > :last-child { margin-right: 0px; } .framer-i9eJ0 .framer-qk93zh > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 344
 * @framerIntrinsicWidth 272
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"v7siRVibc":"image","m3b7mFISp":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramervtIkG1kmq: React.ComponentType<Props> = withCSS(Component, css, "framer-i9eJ0") as typeof Component;
export default FramervtIkG1kmq;

FramervtIkG1kmq.displayName = "Card/Card Category";

FramervtIkG1kmq.defaultProps = {height: 344, width: 272};

addPropertyControls(FramervtIkG1kmq, {v7siRVibc: {__defaultAssetReference: "data:framer/asset-reference,YPywguZEG6pPNzYMYQb4k2Mhm7E.png?originalFilename=Category.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}, m3b7mFISp: {defaultValue: "Background Remover", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramervtIkG1kmq, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})